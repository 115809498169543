<template>
  <div class="banner">
     <Particles
                id="tsparticles"
                :particlesInit="particlesInit"
                :particlesLoaded="particlesLoaded"
                :options="{
                    background: {
                        color: {
                            value: 'transparent'
                        }
                    },
                    fpsLimit: 120,
                    interactivity: {
                        events: {
                            onClick: {
                                enable: false,
                                mode: 'push'
                            },
                            onHover: {
                                enable: false,
                                mode: 'repulse'
                            },
                            resize: true
                        },
                        modes: {
                            bubble: {
                                distance: 400,
                                duration: 2,
                                opacity: 0.8,
                                size: 40
                            },
                            push: {
                                quantity: 4
                            },
                            repulse: {
                                distance: 200,
                                duration: 0.4
                            }
                        }
                    },
                    particles: {
                        color: {
                            value: ['#3687c8', '#b51f83']                     
                        },
                        links: {
                            color: '#ffffff',
                            distance: 150,
                            enable: false,
                            opacity: 0.5,
                            width: 1
                        },
                        collisions: {
                            enable: true
                        },
                        move: {
                            direction: 'none',
                            enable: true,
                            outModes: {
                                default: 'bounce'
                            },
                            random: false,
                            speed: 0.3,
                            straight: false
                        },
                        number: {
                            density: {
                                enable: true,
                                area: 1200
                            },
                            value: 80
                        },
                        opacity: {
                            value: 0.5
                        },
                        shape: {
                            type: 'circle'
                        },
                        size: {
                            value: 2,
                            random: true,
                            animation: {
                                enable: true,
                                speed: 10,
                                minimumValue: 0.1,
                                sync: false
                            },
                            move: {
                                enable: true,
                            },
                        },
                        animation: {
                            enable: true,
                            speed: 20,
                            minimumValue: 0.1,
                            sync: false
                        },
                    },
                    detectRetina: true
                }"
        />
    <div class="box-container title-container">
        <h1>
          Nous accompagnons<br />
          les <strong>Marques</strong>,<br />
          les <strong>Institutions</strong>,<br />
          les <strong>Talents</strong>,<br />
          les <strong>Artistes</strong>,<br />
          les <strong>Célébrités</strong> <br />
          et les <strong>Sportifs de haut niveau</strong><br /><br />
         <span class="focus">à profiter des <span class="second-color"><strong>NFT</strong></span><br /> et à basculer dans le <span class="main-color"><strong>Metaverse</strong></span></span>
      </h1>
      <gradient-bar></gradient-bar>
    </div>
  </div>
  <div class="box-container sub-container">
    <div class="box">
      <gradient-bar :reverse="true"></gradient-bar>
      <div class="text-container display-desk">
        <h2>Nous les aidons à<br />prendre ce virage<br />technologique unique </h2>
        <h3>afin de les faire rayonner autrement et de leur<br />donner un positionnement avant-gardiste<br />vis-à-vis de leurs concurrents</h3>
      </div>
      <div class="text-container display-mobile">
        <h2>Nous les aidons à prendre ce virage technologique unique </h2>
        <h3>afin de les faire rayonner autrement et de leur donner un positionnement avant-gardiste vis-à-vis de leurs concurrents</h3>
      </div>
    </div>
  </div>
  <div class="btns-container">
    <router-link :to="{name : 'NFT'}" class="nft-col">
      <div class="btns-col-container">
        <p class="follow">accompagnement 360°</p>
        <h3>nft</h3>
        <p>Born2Mint vous accompagne de A à Z dans votre projet NFT, de la conception à la commercialisation et promotion en passant par la mise en ligne et le "minting" blockchain</p>
      </div>
    </router-link>
    <router-link :to="{name : 'Metaverse'}" class="metaverse-col">
      <div class="btns-col-container">
        <p class="follow">accompagnement 360°</p>
        <h3>metaverse</h3>
        <p>Born2Mint vous épaule de manière 100% transversale sur votre projet Metaverse : Consulting, Identification du Metaverse adéquat, Achat-Gestion-Revente de terrains virtuels, Création d'univers virtuels 3D, Développement, etc</p>
      </div>
    </router-link>
  </div>
</template>

<script>
import GradientBar from "./../components/GradientBar.vue";

export default {
  name: "Home",
  components : {
    GradientBar
  },
  metaInfo: {
    title: 'Born2Mint - Your NFT Maker & Metaverse Guide',
    meta: [
        { name: 'description', content: 'Born2Mint accompagne les marques, les institutions, les talents, les artistes, les célébrités et les sportifs de haut niveau à lancer et à commercialiser des collections NFT ainsi qu’à basculer dans le Metaverse' },
    ]
  },
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";



.title-container {
  display: flex;
  justify-content: flex-end;
}

h1 {
  color:#fff;
  font-size:40px;
  font-weight: 300;
  text-align: right;
  text-transform: uppercase;
  margin:0;
  letter-spacing: 2px;
}

h1 .focus {
  font-size:50px;
}


h2 {
  font-size:60px;
  font-weight:300;
  text-transform: uppercase;
  margin:0;
  color:#fff;
}

h3 {
  font-size:30px;
  font-weight:300;
  text-transform: uppercase;
  margin: 20px 0 0 0;
  color:#fff;
}

.sub-container {
  display:flex;
  min-height:100vh;
  justify-content: flex-start;
  align-items: center;
}

.sub-container h2 {
  text-align:left;
}

.btns-container {
  display: flex;
}


.nft-col {
  background:#a7358b;
  width:50%;
  padding:120px 0;
  transition:0.5s;
  text-decoration: none;
  position:relative;
  z-index: 1;
}

.nft-col:hover {
  background: darken($color: #a7358b, $amount: 20) ;
}

.metaverse-col {
  background:#009bd2;
  width:50%;
  padding:120px 0;
  transition:0.5s;
  text-decoration: none;
  position:relative;
  z-index: 1;
}

.metaverse-col:hover {
background: darken($color: #009bd2, $amount: 20)  
}

.btns-col-container {
  width: 600px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin:0 auto;
}

.btns-col-container p.follow {
  color:#fff;
  font-size:30px;
  text-transform: uppercase;
  font-weight:300;
  margin:0;
}

.metaverse-col .follow, .metaverse-col h3, .metaverse-col .btns-col-container p {
  text-align: right;
}

.btns-col-container h3 {
  margin:10px 0;
  font-size:60px;
  font-weight:600;
  line-height: 1em;
}

.btns-col-container p {
  color:#fff;
  font-size:18px;
  // text-align: justify;
  font-weight:300;
  margin: 10px 0 0 0;
}

.box {
  display: flex;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


@media screen and (max-width:1024px) {
  h1 {
    font-size:30px;
  }

  h1 .focus {
    font-size:35px;
  }

  h2 {
    font-size:40px;
  }

  h3 {
    font-size:25px;
  }
}

@media screen and (max-width:768px) {

  .sub-container {
    min-height: auto;
    padding:100px 0;
  }

  .btns-container {
    flex-direction: column;
  }

  .nft-col, .metaverse-col {
    width:100%;
  }

  .follow {
    font-size:25px;
  }

  .btns-col-container h3{
    font-size:40px;
  }

  h1 .focus {
    font-size:35px;
  }
}
</style>